@import '/src/theme/common.less';

.ant-layout-sider {
  &.side-bar {
    .header-trigger {
      position: absolute;
      //top: 24px;
      top: 8px;
      right: -36px;
      z-index: 1;
      padding: 0;
      width: 36px;
      height: 42px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      background: #294b76;
      border-radius: 0 2px 2px 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
      border: 0;
    }
  }

  &.ant-layout-sider-collapsed {
    &.side-bar {
      .bottom-trigger {
        max-width: 80px;
      }
    }
  }
}

.yahuikeji-layout-sideBar {
  background: #294b76 !important;
  // z-index: 100;

  .yahuikeji-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .box {
      height: calc(100vh - 100px);
      overflow: hidden;

      &:hover {
        overflow-y: auto;
      }

      .menu-scrollbar-beautify();

    }

    .footer {
      border-color: #294b76;

      .bottom-trigger {
        // position: fixed;
        max-width: 200px;
        width: 100%;
        min-width: 80px !important;
        bottom: 0;
        left: 0;
        z-index: 1;
        padding: 0;
        border: none;
        background: #294b76;
        transition: all 0.2s;

        span {
          color: #fff;
        }
      }
    }
  }
}
@white: #fff;@black: #000;@blue-2: #bae7ff;@blue-8: #294B76;@blue-9: #003a8c;@blue-10: #233151;@layout-header-background: @white;@layout-sider-background: @blue-8;@layout-header-height: 60px;@menu-item-vertical-margin: 0;@menu-dark-bg: @blue-8;@dropdown-edge-child-vertical-padding: 0;@border-color-base: hsv(0, 0, 85%);@border-color-split: hsv(0, 0, 94%);@border-color-inverse: @white;@border-width-base: 1px;@border-style-base: solid;@text-color-secondary-dark: fade(@white, 65%);@text-color: fade(@black, 85%);