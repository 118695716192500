.yahuikeji-global-message {
    border: 1px solid #FF3838;
    border-radius: 8px;
    color: #FF3838;
    display: flex;
    align-items: center;
    line-height: 14px;
    cursor: pointer;

    .left {
        min-height: 34px;
        display: flex;
        align-items: center;
        padding: 6px 10px;
        background-color: #FFD5D5;
        border-radius: 8px 0 0 8px;
        cursor: pointer;

        .yahuikeji-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;

            .icon_globalMessage {
                fill: #FF3838;
            }
        }

        .yahuikeji-title {
            padding-left: 6px;
            display: flex;
            align-items: center;
            font-weight: bold;
        }
    }

    .right {
        display: flex;
        padding-left: 10px;

        .title {
            padding-right: 12px;

            .label {
                padding-right: 6px;
                color: #5F5F5F;
            }

            .value {
                font-weight: bolder;
                font-size: 18px;
            }
        }
    }
}

.yahuikeji-global-message-flase {
    // animation: flash 1s infinite;
}

.yahuikeji-global-message-no-new-alert {
    color: #757575;
    border-color: #AAAAAA;
    border-radius: 9px;

    .left {
        background-color: #CACACA;

        .yahuikeji-icon {
            display: none;
        }
    }
}


@keyframes flash {
    0% {
        border-color: red;
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // 红色阴影
    }

    50% {
        // border-color: transparent;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); // 原始阴影
        border-color: rgba(255, 192, 192, 1); /* 淡红色边框 */
        box-shadow: 0 0 10px rgba(255, 192, 192, 0.5); /* 淡红色阴影 */
    }

    100% {
        border-color: red;
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // 红色阴影
    }
}

.yahuikeji-aduio {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
        padding-right: 10px;
    }
}
@white: #fff;@black: #000;@blue-2: #bae7ff;@blue-8: #294B76;@blue-9: #003a8c;@blue-10: #233151;@layout-header-background: @white;@layout-sider-background: @blue-8;@layout-header-height: 60px;@menu-item-vertical-margin: 0;@menu-dark-bg: @blue-8;@dropdown-edge-child-vertical-padding: 0;@border-color-base: hsv(0, 0, 85%);@border-color-split: hsv(0, 0, 94%);@border-color-inverse: @white;@border-width-base: 1px;@border-style-base: solid;@text-color-secondary-dark: fade(@white, 65%);@text-color: fade(@black, 85%);