.scrollbar-beautify() {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #dddddd;
    }

    &::-webkit-scrollbar-thumb:hover {
        border-radius: 5px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #a1a1a1;
    }

    &::-webkit-scrollbar-track {
        border-radius: 5px;
        background: #f6f6f6;
    }
}

.menu-scrollbar-beautify() {
    &::-webkit-scrollbar {
        width: 3px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #dddddd;
    }

    &::-webkit-scrollbar-thumb:hover {
        border-radius: 3px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #a1a1a1;
    }

    &::-webkit-scrollbar-track {
        border-radius: 3px;
        background: #294b76;
    }
}

.text-overflow-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis; //文本溢出显示省略号
    white-space: nowrap; //文本不会换行
}