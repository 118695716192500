// @import 'node_modules/antd/dist/antd.less';

body {
  padding: 0px 0px !important;
  margin: 0px;
}

* {
  // font-family: 'Segoe UI', 'PingFang SC', 'Microsoft YaHei', -apple-system, BlinkMacSystemFont, 
  // Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  // 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  font-family: 'YH Arial', 'YH Segoe UI', 'YH Microsoft YaHei', 'PingFang SC', 'Microsoft YaHei', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.full-height-page {
  min-height: 100vh;

  &.with-header {
    min-height: calc(~'100vh - 100px');
  }
}

/* Buttons */
.ant-btn {

  &.ant-btn-primary:not(.ant-btn-circle),
  &.ant-btn-primary:not(.ant-btn-icon-only),
  &.ant-btn-default {
    min-width: 70px;
  }

  &.ant-btn-primary {
    &.header-trigger {
      min-width: auto;
    }

    &:hover,
    &:focus {
      &.header-trigger {
        background: @blue-8;
      }
    }
  }
}

/* Tables */
.clickable-row {
  .ant-table-tbody {
    &>tr.ant-table-row {
      &:hover>td {
        cursor: pointer;
      }
    }
  }
}

/* Menu */
.ant-menu-dark {
  .ant-menu-item {
    display: flex;
    align-items: center;
    line-height: 1;

    &:hover,
    &.ant-menu-item-selected {
      &>svg {
        fill: @white;
      }
    }

    &>svg {
      fill: @text-color-secondary-dark;
    }
  }

  .ant-menu-submenu {

    &:hover,
    &.ant-menu-submenu-selected {
      &>svg {
        fill: @white;
      }
    }

    .ant-menu-submenu-title {
      &>svg {
        fill: @text-color-secondary-dark;
      }
    }
  }
}

/* DatePicker */
.ant-picker {
  width: 100%;
}

/* Form */
.ant-row.ant-form-item .ant-form-item-control-input-content {
  max-width: 300px !important;
}

.ant-row.ant-form-item.full .ant-form-item-control-input-content {
  max-width: 100% !important;
}

.ant-spin-nested-loading {
  &.loader-wrap>div>.ant-spin {
    max-height: 100vh;
    z-index: 99999;
  }
}

.ant-page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-tag {
  &:last-child {
    margin: 0;
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.Analysis .ant-row.ant-form-item .ant-form-item-control-input-content {
  max-width: 380px !important;
}

.ant-tooltip {
  max-width: 650px;
}

.ant-pagination-options {
  .ant-select-dropdown {
    // top: -172px !important;
    top: -110px !important;
  }
}

// //美化属性
// body {
//   overflow: auto;
// }

// //方块大小
// body::-webkit-scrollbar {
//   width: 6px;
//   height: 6px;
// }

// body::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   background: #bfbfbf;
// }

// body::-webkit-scrollbar-thumb:hover {
//   border-radius: 5px;
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background: #a1a1a1;
// }

// body::-webkit-scrollbar-track {
//   border-radius: 5px;
//   background: #f6f6f6;
// }
@white: #fff;@black: #000;@blue-2: #bae7ff;@blue-8: #294B76;@blue-9: #003a8c;@blue-10: #233151;@layout-header-background: @white;@layout-sider-background: @blue-8;@layout-header-height: 60px;@menu-item-vertical-margin: 0;@menu-dark-bg: @blue-8;@dropdown-edge-child-vertical-padding: 0;@border-color-base: hsv(0, 0, 85%);@border-color-split: hsv(0, 0, 94%);@border-color-inverse: @white;@border-width-base: 1px;@border-style-base: solid;@text-color-secondary-dark: fade(@white, 65%);@text-color: fade(@black, 85%);