@import '/src/theme/common.less';

.main-wrapper {

  .yahuikeji-main {
    height: calc(100vh - 0px);
    overflow: auto;
    .scrollbar-beautify();

    .yahuikeji-layout-box {
      // height: calc(100vh - 60px);
      // overflow: auto;
      // .scrollbar-beautify();

      .content {
        margin: 20px;

      }
    }
  }

  .ant-layout-sider-zero-width-trigger {
    top: 24px;
  }
}

@media only screen and (max-width: 680px) {
  .ant-layout-sider {
    z-index: 2;
    top: 0;
    bottom: 0;
    position: absolute;
  }
}

.topSearchForm .ant-form-item-with-help {
  margin-bottom: 0;
}

.topSearchForm .ant-form-item-explain {
  display: none;
}

.pageHeader {
  padding-bottom: 10px;
}

.modal-box .ant-modal-body {
  padding: 3px;
}

.userTab .ant-card-bordered {
  border: none;
}

.userTab .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
  padding: 0;
}

.user-tabs-link {
  display: block;
  width: 160px;
  line-height: 30px;
  text-align: right;
  padding-right: 20px;
}

.yahuikeji-header-title {
  margin-left: -25px;
  color: rgba(0, 0, 0, 0.8);
}

.yahuikeji-layout {}

.yahuikeji-layout-header {
  background: #fff;
  height: 60px;
  z-index: 101;
  position: relative;
}

.ant-menu-inline-collapsed-tooltip {
  z-index: 99;
}
@white: #fff;@black: #000;@blue-2: #bae7ff;@blue-8: #294B76;@blue-9: #003a8c;@blue-10: #233151;@layout-header-background: @white;@layout-sider-background: @blue-8;@layout-header-height: 60px;@menu-item-vertical-margin: 0;@menu-dark-bg: @blue-8;@dropdown-edge-child-vertical-padding: 0;@border-color-base: hsv(0, 0, 85%);@border-color-split: hsv(0, 0, 94%);@border-color-inverse: @white;@border-width-base: 1px;@border-style-base: solid;@text-color-secondary-dark: fade(@white, 65%);@text-color: fade(@black, 85%);