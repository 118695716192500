// @import '~antd/lib/style/themes/default.less';

.logo-wrapper {
  width: 100%;
  height: 30px;
  margin: 0 5px 0 40px;
  line-height: 0;
  fill: @white;

  &.dark {
    fill: @text-color;
  }

  .logo-source {
    width: 180px;
    height: 65px;
  }
}

.logo-wrapper-new {
  line-height: 0;
  position: absolute;
  left: 10px;
  transform: scale(0.8);

  &.dark {
    fill: @text-color;
  }
}
@white: #fff;@black: #000;@blue-2: #bae7ff;@blue-8: #294B76;@blue-9: #003a8c;@blue-10: #233151;@layout-header-background: @white;@layout-sider-background: @blue-8;@layout-header-height: 60px;@menu-item-vertical-margin: 0;@menu-dark-bg: @blue-8;@dropdown-edge-child-vertical-padding: 0;@border-color-base: hsv(0, 0, 85%);@border-color-split: hsv(0, 0, 94%);@border-color-inverse: @white;@border-width-base: 1px;@border-style-base: solid;@text-color-secondary-dark: fade(@white, 65%);@text-color: fade(@black, 85%);