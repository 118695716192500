.yahuikeji-profile {
    position: relative;

    .readStatus {
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: red;
        top: 14px;
        right: -4px;
    }
}

.version-info-modal {
    .ant-modal-content {
        padding: 0;

        .ant-modal-close {
            top: 5px;
            right: 5px;
        }
    }

    .version-info {
        padding: 40px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            width: 200px;
        }

        .v-u {
            .version {
                margin-top: 6px;
                font-weight: 700;
                display: flex;

                .label {
                    width: 63px;
                }
            }

            .update-time {
                font-weight: 500;
                display: flex;

                .label {
                    width: 63px;
                }
            }
        }

        .copyright {
            font-size: 12px;
            padding-top: 6px;
        }
    }
}
@white: #fff;@black: #000;@blue-2: #bae7ff;@blue-8: #294B76;@blue-9: #003a8c;@blue-10: #233151;@layout-header-background: @white;@layout-sider-background: @blue-8;@layout-header-height: 60px;@menu-item-vertical-margin: 0;@menu-dark-bg: @blue-8;@dropdown-edge-child-vertical-padding: 0;@border-color-base: hsv(0, 0, 85%);@border-color-split: hsv(0, 0, 94%);@border-color-inverse: @white;@border-width-base: 1px;@border-style-base: solid;@text-color-secondary-dark: fade(@white, 65%);@text-color: fade(@black, 85%);