.yahuikeji-locales {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;

    .yahuikeji-locales-dropdown {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        width: 110px;
        color: #7F7F7F;

        .anticon{
            margin-left: 5px;
            &:first-child{
                margin-right: 5px;
            }
        }
    }
}
@white: #fff;@black: #000;@blue-2: #bae7ff;@blue-8: #294B76;@blue-9: #003a8c;@blue-10: #233151;@layout-header-background: @white;@layout-sider-background: @blue-8;@layout-header-height: 60px;@menu-item-vertical-margin: 0;@menu-dark-bg: @blue-8;@dropdown-edge-child-vertical-padding: 0;@border-color-base: hsv(0, 0, 85%);@border-color-split: hsv(0, 0, 94%);@border-color-inverse: @white;@border-width-base: 1px;@border-style-base: solid;@text-color-secondary-dark: fade(@white, 65%);@text-color: fade(@black, 85%);